import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Text,
    VStack,
    Center,
    Box
} from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

/**
 * Componente de modal "NoticesModal"
 * Props:
 * - isOpen: boolean (se a modal está aberta)
 * - onClose: função (callback para fechar a modal)
 * - title: string (título exibido no topo da modal)
 * - text: string (texto a ser exibido na modal)
 * - isEnableTimer: boolean (se true, habilita contagem regressiva)
 * - timerSeconds: number (quantos segundos terá a contagem, se isEnableTimer for true)
 */
export const NoticesModal = ({
    isOpen,
    onClose,
    title = 'Atenção',
    text,
    isEnableTimer = false,
    timerSeconds = 10
}) => {
    const [countdown, setCountdown] = useState(timerSeconds);
    const [timerActive, setTimerActive] = useState(isEnableTimer);

    // Reseta o timer sempre que a modal é aberta ou quando suas props mudam
    useEffect(() => {
        if (isOpen) {
            setCountdown(timerSeconds);
            setTimerActive(isEnableTimer);
        }
    }, [isOpen, isEnableTimer, timerSeconds]);

    useEffect(() => {
        let interval = null;

        if (timerActive && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else if (timerActive && countdown <= 0) {
            // Contagem terminou
            setTimerActive(false);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerActive, countdown]);

    // Se o timer está ativo, não permite fechar pelo overlay,
    // não mostra botão fechar e botão X.
    const closeOnOverlayClick = !timerActive;
    const showCloseButton = !timerActive;
    const showCloseIcon = !timerActive;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="lg"
            isCentered
            closeOnOverlayClick={closeOnOverlayClick}
            closeOnEsc={closeOnOverlayClick}
        >
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                {showCloseIcon && <ModalCloseButton color="white" />}

                <Text fontSize="xl" color={colors.white75} mb={4}>
                    {title}
                </Text>

                <ModalBody>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        {text}
                    </Text>

                    {timerActive && (
                        <Center>
                            <Box
                                borderRadius="50%"
                                backgroundColor={colors.white25}
                                w="60px"
                                h="60px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text fontSize="lg" color={colors.white}>
                                    {countdown}
                                </Text>
                            </Box>
                        </Center>
                    )}
                </ModalBody>

                <ModalFooter>
                    {showCloseButton && (
                        <VStack spacing={4} w="full">
                            <YellowButton text="Fechar" onClick={onClose} />
                        </VStack>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};